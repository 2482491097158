import React from 'react';
import JnJLogo from '../assets/img/jnj_logo.png';
import config from '../config.json';

export default function Footer() {
    return (
        <>
            <div className='row'>
                <div className='footer-wrapper'>
                    <div className='footer-inner-wrapper'>
                        <div className='footer-company-logo'><img src={JnJLogo}></img></div>
                        <div className='footer-copyright-message'>{config.footer.footer_copyright_message}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
