import CryptoJS from 'crypto-js';
const key = 'c0V!dP@$$#2022';

export function encrypt(plaintext) {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(plaintext), key).toString();
    return ciphertext;
}

export function decrypt(ciphertext) {
    var bytes = CryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}