import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";


export default function Loader() {
    return (
        <div className='full-screen'>
            <div className='d-flex justify-content-center'>
                <div className="spinner-grow mx-1" style={{ "width": "2rem", "height": "2rem", "color": "#6cc24a" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow mx-1" style={{ "width": "2rem", "height": "2rem", "color": "#ff8200" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow mx-1" style={{ "width": "2rem", "height": "2rem", "color": "#000099" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    )
}
