import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../CSS/style.css';
import config from '../config.json';
import CovidPass from './CovidPass';
import Header from "./Header";
import Footer from "./Footer";
import languageJSON from "../lang.json"

export default function NewUser(props) {
    const [renderNextPage, setNextPage] = useState(false); //state variable to hold the current state of the app and change the state to render the CovidPass component on selecting the language

    function openCovidPass() { //function to open the CovidPass component after setting the new language
        localStorage.setItem('language', document.querySelector('input[name="lang-radio"]:checked').value); //sets the 'language' key to the selected language in the local storage.
        setNextPage(true); //updates the renderNextPage state variable
    }

    return (
        <>
            <div className='row'>
                <div className='new-user-wrapper'>
                    {renderNextPage === true ? <CovidPass email={props.email} /> : <><Header /> <div className='first-time-visit-message'>{config.new_user.first_time_visit_message}</div>
                        <div className='first-time-visit-description'>{config.new_user.first_time_visit_description}</div>
                        <div className='first-time-visit-warning'><strong>{config.new_user.first_time_visit_warning_bold_title}</strong>{config.new_user.first_time_visit_warning_message}</div>
                        <div className='language-selection-wrapper'>
                            <div className='language-selection-title'>{config.new_user.first_time_visit_language_selection_title}</div>
                            {Object.keys(languageJSON).map((lang, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className='language-radio-button'>
                                            <div className='lang-radio'><input type="radio" id={lang} name="lang-radio" value={lang} defaultChecked={lang === "English" && true} /></div>
                                            <div className='language-name'>{lang}</div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            <input type='button' className='save-button' value={config.new_user.first_time_visit_language_selection_button_text} onClick={() => openCovidPass()}></input>
                        </div><Footer /></>}
                </div>
            </div>
        </>
    )
}
