import React from "react";
import Home from "./Components/Home";
import { isAuthenticated, UserData } from "./auth/Authorization";



function App() {


  return (
    <>
      {isAuthenticated && <Home email={UserData.email}/>}
    </>
  );
}

export default App;