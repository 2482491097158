import React, { useState, useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import '../CSS/style.css';
import config from '../config.json';
import GreenTick from '../assets/img/tick.png';
import GreenCircle from '../assets/img/green_circle.png';
import ExclaimationMark from '../assets/img/exclamation.png';
import AmberCircle from '../assets/img/amber_circle.png';
import ErrorSign from '../assets/img/notsupported.png';
import languageJSON from '../lang.json';
import Loader from './Loader';
import axios from 'axios';
import Header from "./Header";
import Footer from "./Footer";
import { decrypt } from '../encryption';

export default function CovidPass(props) {
  const [UserData, setUserData] = useState([]); //stores user data received from the HR Data Hub API
  const [errorFlag, setErrorFlag] = useState(false); //flag to show Error Page
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(localStorage.getItem('language')); //state variable to hold the current selected language from the local storage and on update, re-renders the DOM 
  useEffect(() => { //runs in the componentDidMount lifecyle stage of the component
    let decryptedApiKey = decrypt(process.env.REACT_APP_COVID_PASS_API_KEY)
    let header = { 'apikey': decryptedApiKey, 'Content-Type': 'application/json' }
    axios({ //GET call to retrieve user information from the HR Data Hub API
      method: 'GET',
      url: process.env.REACT_APP_COVID_PASS_API_URL + "/covid-pass?email='" + props.email + "'", //configuring the URL endpoint for the GET call
      headers: header
    }).then((res) => {
      if (res.data != "") { //check if response is not empty
        if (res.data.status_code === 200) { //check if response status code is 200
          //check if result is not null and askgs_vv is 'y' then set the user data, else set user as "None"
          res.data.result != null ? (res.data.result[0].askgs_vv.toLowerCase() === 'y' ? setUserData(res.data) : setUserData("None")) : setUserData("None");
        } else { //if response status code is not 200
          setErrorFlag(true); //set state of errorFlag
        }
      } else { //if response is empty
        setErrorFlag(true); //set state of errorFlag
      }
    }).catch((error, res) => {
      setErrorFlag(true);
    });
  }, [])

  const date = new Date(); //declares new Date object


  function changeCurrentLanguage() { //function to update the new language selected from the dropdown in local storage and re-renders the component
    localStorage.setItem('language', document.getElementById('languageOptions').value); //updates the newly selected language in the local storage
    setCurrentSelectedLanguage(localStorage.getItem('language')); //updates the currentSelectedLanguage state variable and re-renders the component
  }

  function getLanguageSpecificAccomodation() { //function to return accomodation details based on the current selected language
    let finalAccomodationString = ""; //initialing the final accomodation details string
    let accomodationDetailsArray = UserData.result[0].accomm_details.split(", "); //split the comma(,) seperated data
    accomodationDetailsArray.forEach(accomodation => { //run for each element in the array
      accomodation = accomodation.trim(); //remove any leading or trailing whitespaces
      if (languageJSON[currentSelectedLanguage][accomodation] != undefined) { //check if translation is available in lang.json
        let langAccomodation = languageJSON[currentSelectedLanguage][accomodation]; //convert the accomodation into the current selected language
        finalAccomodationString += finalAccomodationString ? (", " + langAccomodation) : langAccomodation; //append the final accomodation details string in the current selected language
      } else { //if translation not available
        finalAccomodationString += finalAccomodationString ? (", " + accomodation) : accomodation; //append the final accommodation details string as received from the api
      }
    });
    return finalAccomodationString;
  }

  let showGreenStatusFlag, showAccomodationFlag, langCode;  
  langCode = languageJSON[currentSelectedLanguage]["lang_code"]; //initializing the lang code from the lang.json
  //if UserData is not null 
  if (UserData.length != 0) { 
    if (UserData.result) {
      if (UserData.result[0].vac_accomm_status) {
        showGreenStatusFlag = config["covid_pass"]["green_status"].includes(UserData.result[0].vac_accomm_status.toLowerCase()); //flag to show green status
        showAccomodationFlag = config["covid_pass"]["show_accomodation_status"].includes(UserData.result[0].vac_accomm_status.toLowerCase()); //flag to show accommodation instead of vaccine status
      }
    }
  }

  return (
    <>
      {UserData.length != 0 || errorFlag ? <><Header /><div className='row'>
        <div className='covid-pass-heading'>
          <div className='covid-pass-heading-title'>{config.covid_pass.covid_pass_heading}</div>
          <select className='covid-pass-languages' id='languageOptions' defaultValue={currentSelectedLanguage} onChange={() => changeCurrentLanguage()}>
            {Object.keys(languageJSON).map((lang, index) => {
              return (<option key={index} value={lang}>{lang}</option>)
            })}
          </select>
        </div>
        <div className={!errorFlag ? UserData != "None" ? showGreenStatusFlag ? "vaccine-green" : "vaccine-amber" : "vaccine-grey" : "vaccine-grey"}>
          <div className={!errorFlag ? UserData != "None" ? "covid-pass-results-heading-vaccine-supported" : "covid-pass-results-heading-vaccine-not-supported" : "covid-pass-results-heading-vaccine-not-supported"}>{languageJSON[currentSelectedLanguage]["covid_pass_results"]}</div>
          <div className='vaccine-details'>
            <div className='date-heading'>{languageJSON[currentSelectedLanguage]["date_heading"]}</div>
            <div className='todays-date'>{!langCode ? date.toLocaleDateString("en-US", { month: 'short', day: 'numeric' }) : date.toLocaleDateString(langCode, { month: 'short', day: 'numeric' })}</div>
            {!errorFlag ? UserData != "None" ? <><div className='vaccine-status'>
              <img className='icon-circle' src={showGreenStatusFlag ? GreenCircle : AmberCircle}></img>
              <img className='icon-mark' src={showGreenStatusFlag ? GreenTick : ExclaimationMark}></img>
              <div className={showGreenStatusFlag ? "vaccine-status-message-green" : "vaccine-status-message-amber"}>{showAccomodationFlag ? getLanguageSpecificAccomodation() : !languageJSON[currentSelectedLanguage][UserData.result[0].vac_accomm_status] ? UserData.result[0].vac_accomm_status : languageJSON[currentSelectedLanguage][UserData.result[0].vac_accomm_status]}</div>
            </div>
              <div className='person-name'>{UserData.result[0].first_name + " " + UserData.result[0].last_name}</div></> : <><div className='vaccine-not-supported'><img src={ErrorSign}></img></div>
              <div className='vaccine-not-supported-title'>{languageJSON[currentSelectedLanguage]["vaccine_not_supported_title"]}</div>
              <div className='vaccine-not-supported-message'>{languageJSON[currentSelectedLanguage].error_message}</div></> : <><div className='vaccine-not-supported'><img src={ErrorSign} /></div>
              <div className='error-wrapper'><div className='error-heading'>{languageJSON[currentSelectedLanguage]["error_message_title"]}</div><div className='error-message'>{languageJSON[currentSelectedLanguage]["error_message_description"] + " "}<a href={config["error_screen"]["error_screen_link"][Object.keys(config["error_screen"]["error_screen_link"])]}>{Object.keys(config["error_screen"]["error_screen_link"])}</a>.</div></div></>}
          </div>
          <div className='additional-guidance-wrapper'>
            <div className='additional-title-heading'>{languageJSON[currentSelectedLanguage]["additional_guidance_title"]}</div>
            <div className='additional-title-desctiption'>{languageJSON[currentSelectedLanguage]["additional_guidance_description"]}</div>
            <button className='guidance-button' onClick={() => window.open(config.covid_pass.guidance_button_url, "_blank")}>{languageJSON[currentSelectedLanguage]["guidance_button_text"]}</button>
          </div>
        </div>
      </div><Footer /></> : <Loader />}
    </>
  )
}
