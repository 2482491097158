import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../config.json"
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ErrorSign from '../assets/img/notsupported.png';
import languageJSON from '../lang.json';
import '../CSS/style.css';



export default function UnauthorizedPage() {

    return (
        <div className='container-fluid px-lg-5 py-lg-5'>
            <Header/>
            <div className='row text-center' style={{ 'font-family': 'sans-serif' }}>
                <div className='vaccine-not-supported'><img src={ErrorSign} /></div>
                <div className='error-wrapper'>
                    <div className='error-heading'>{languageJSON["English"]["error_message_title"]}</div>
                    <div className='error-message'>{languageJSON["English"]["error_message_description"] + " "}<a href={config["error_screen"]["error_screen_link"][Object.keys(config["error_screen"]["error_screen_link"])]}>{Object.keys(config["error_screen"]["error_screen_link"])}</a>.</div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
