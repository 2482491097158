import React from 'react'
import CovidPass from "./CovidPass";
import NewUser from "./NewUser";
import '../CSS/style.css';
import "bootstrap/dist/css/bootstrap.min.css";
import languageJSON from "../lang.json"


export default function Home(props) {

    return (
        <>
            <div className='container-fluid px-lg-5'>
                {localStorage.getItem('language') == null ? <NewUser email={props.email} /> : (Object.keys(languageJSON).includes(localStorage.getItem('language')) ? <CovidPass email={props.email}/> : <NewUser email={props.email}/>)}
            </div>
        </>
    )
}
